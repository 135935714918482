export const datepicker = {
  datepicker: {
    date: {
      padding: '.5rem',
      width: '2.5rem',
      height: '2.5rem',
    },
    year: {
      padding: '.5rem',
    },
    month: {
      padding: '.5rem',
    },
    colorScheme: {
      light: {
        today: {
          color: 'var(--p-text-color)',
          background: 'var(--p-text-color-third)',
        },
        date: {
          selectedBackground: '#e3f2fd',
          selectedColor: 'var(--p-text-color)',
          hoverBackground: '#e9ecef',
          hoverColor: 'var(--p-text-color)',
        },
        week: {
          day: {
            fontWeight: 'bold',
          },
        },
        header: {
          borderColor: '#dee2e6',
        },
        select: {
          monthPadding: '.5rem',
          monthHoverBackground: 'transparent',
          monthHoverColor: 'var(--p-primary-color)',
          yearHoverBackground: 'transparent',
          yearHoverColor: 'var(--p-primary-color)',
        },
        title: {
          fontWeight: '600',
        },
      },
    },
  },
};
