export const mask = {
  mask: {
    colorScheme: {
      light: {
        background: 'rgba(0,0,0,0.1)',
        color: 'var(--p-text-color)',
      },
    },
  },
};
