export const badge = {
  badge: {
    colorScheme: {
      light: {
        danger: {
          background: '#d32f2f',
        },
        borderRadius: '10px',
        minWidth: 'revert',
        dotSize: '1.5rem',
      },
    },
  },
};
