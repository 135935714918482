export const tree = {
  tree: {
    colorScheme: {
      light: {
        padding: '0',
        gap: '6px',
        node: {
          hoverColor: 'revert',
        },
      },
    },
  },
};
