export const accordion = {
  accordion: {
    colorScheme: {
      light: {
        header: {
          background: '#f8f9fa',
          hoverBackground: '#e9ecef',
          activeBackground: '#f8f9fa',
          activeHoverBackground: '#e9ecef',
          borderColor: '#dee2e6',
          firstTopBorderRadius: '0',
          lastBottomBorderRadius: '0',
          color: 'var(--p-text-color)',
          hoverColor: 'var(--p-text-color)',
          activeHoverColor: 'var(--p-text-color)',
        },
        panel: {
          borderColor: '#dee2e6',
        },
        content: {
          padding: '1.125rem',
        },
      },
    },
  },
};
