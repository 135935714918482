export const list = {
  list: {
    colorScheme: {
      light: {
        option: {
          focusBackground: '#dee2e6',
          focusColor: 'var(--p-text-color)',
        },
        header: {
          padding: '0.5rem 1rem',
        },
      },
    },
    option: {
      padding: '0.5rem 1rem',
      borderRadius: '0',
    },
    padding: '0.5rem 0',
  },
};
