export const tag = {
  tag: {
    colorScheme: {
      light: {
        success: {
          color: 'var(--p-surface-0)',
          background: 'var(--p-green-500)',
        },
        danger: {
          color: 'var(--p-surface-0)',
          background: 'var(--p-red-500)',
        },
        borderRadius: 'var(--p-border-radius-sm)',
      },
    },
  },
};
