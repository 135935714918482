/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CaseCreatedOrUpdatedResponse } from '../../models/case-created-or-updated-response';
import { CaseExternal } from '../../models/case-external';

export interface CreateOrUpdateCaseForExt$Params {

/**
 * tenant shortcode
 */
  tenant_short_code: string;

/**
 * External id of a resource.
 */
  external_id: string;
      body: CaseExternal
}

export function createOrUpdateCaseForExt(http: HttpClient, rootUrl: string, params: CreateOrUpdateCaseForExt$Params, context?: HttpContext): Observable<StrictHttpResponse<CaseCreatedOrUpdatedResponse>> {
  const rb = new RequestBuilder(rootUrl, createOrUpdateCaseForExt.PATH, 'put');
  if (params) {
    rb.path('tenant_short_code', params.tenant_short_code, {"style":"simple","explode":false});
    rb.path('external_id', params.external_id, {"style":"simple","explode":false});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CaseCreatedOrUpdatedResponse>;
    })
  );
}

createOrUpdateCaseForExt.PATH = '/api/v1/external/{tenant_short_code}/cases/{external_id}';
