export const treeselect = {
  treeselect: {
    colorScheme: {
      light: {
        tree: {
          padding: '.5rem 1rem',
        },
      },
    },
  },
};
