import {
  VehicleSidebarActions,
  VehicleSidebarActionTypes,
} from '../actions/vehicle-sidebar.action';
import { Status } from '../../interfaces/status.enum';
import { ErrorResponse } from '../../interfaces/error.interface';

export interface VehicleSidebarState {
  manageDialogOpenStatus: boolean;
  manageDialogRequestStatus: Status;
  manageDialogError: ErrorResponse | string | null;
  associationSidebarOpenStatus: boolean;
  associationSidebarRequestStatus: Status;
  associationSidebarError: string | null;
}

export const vehicleSidebarInitialState: VehicleSidebarState = {
  manageDialogOpenStatus: false,
  manageDialogRequestStatus: Status.Default,
  manageDialogError: null,
  associationSidebarOpenStatus: false,
  associationSidebarRequestStatus: Status.Default,
  associationSidebarError: null,
};

export function vehicleSidebarReducer(
  state: VehicleSidebarState = vehicleSidebarInitialState,
  action: VehicleSidebarActions
): VehicleSidebarState {
  switch (action.type) {
    case VehicleSidebarActionTypes.SetManageDialogOpenStatus: {
      return {
        ...state,
        manageDialogOpenStatus: action.payload.open,
      };
    }

    case VehicleSidebarActionTypes.SetManageDialogRequestStatus: {
      return {
        ...state,
        manageDialogRequestStatus: action.payload.status,
        manageDialogError: action.payload.error ? action.payload.error : null,
      };
    }

    case VehicleSidebarActionTypes.SetAssociationSidebarOpenStatus: {
      return {
        ...state,
        associationSidebarOpenStatus: action.payload.open,
      };
    }

    case VehicleSidebarActionTypes.SetAssociationSidebarRequestStatus: {
      return {
        ...state,
        associationSidebarRequestStatus: action.payload.status,
        associationSidebarError: action.payload.error ? action.payload.error : null,
      };
    }

    default: {
      return state;
    }
  }
}
