export const text = {
  text: {
    color: '#495057',
    colorSecondary: '#6c757d',
    colorThird: '#ced4da',
    mutedColor: '#6c757d',
    hoverColor: '#6c757d',
    // mutedColor?: string;
    // hoverMutedColor?: string;
  },
};
