export const primary = {
  primary: {
    50: '#e4f2fe',
    100: '#cae6fc',
    200: '#a0d2fa',
    300: '#75bef8',
    400: '#4baaf5',
    500: '#2196f3',
    600: '#1c80cf',
    700: '#1769aa',
    800: '#125386',
    900: '#0d3c61',
    950: '#0d3c61',
  },
};
