export const button = {
  button: {
    colorScheme: {
      light: {
        text: {
          primary: {
            hover: {
              background: '#2196f30a',
            },
            active: {
              background: '#2196f329',
            },
          },
          danger: {
            hover: {
              background: '#d32f2f0a',
            },
            active: {
              background: '#d32f2f29',
            },
          },
          secondary: {
            color: 'var(--p-text-color-secondary)',
            hoverBackground: 'none',
            activeBackground: 'none',
          },
          plain: {
            hoverBackground: '#e9ecef',
            activeBackground: '#e9ecef',
          },
        },
        primary: {
          hover: {
            background: '#0d89ec',
          },
          active: {
            background: '#0b7ad1',
          },
        },
        outlined: {
          primary: {
            borderColor: 'var(--p-primary-color)',
          },
          danger: {
            borderColor: 'var(--p-danger-color)',
          },
        },
        secondary: {
          background: '#607d8b',
          activeBackground: '#56717d',
          hoverBackground: '#56717d',
          borderColor: '#607d8b',
          activeBorderColor: '#56717d',
          hoverBorderColor: '#56717d',
          color: 'var(--p-surface-0)',
          activeColor: 'var(--p-surface-0)',
          hoverColor: 'var(--p-surface-0)',
        },
      },
    },
    label: {
      fontWeight: '400',
    },
    paddingY: '0.5rem',
    paddingX: '1rem',
  },
};
