export const formField = {
  formField: {
    disabledBackground: 'var(--p-surface-0)',
    borderColor: 'var(--p-text-color-third)',
    hoverBorderColor: 'var(--p-primary-500)',
    color: 'var(--p-text-color)',
    placeholderColor: 'var(--p-text-color-secondary)',
    invalidPlaceholderColor: 'var(--p-text-color-secondary)',
    iconColor: 'var(--p-text-color-secondary)',
    paddingX: '0.5rem',
    focusRingShadow: '0 0 0 .2rem var(--p-primary-200)',
    borderRadius: 'var(--p-border-radius-sm)',
  },
};
