export const panel = {
  panel: {
    colorScheme: {
      light: {
        contentPadding: '1.125rem',
        borderRadius: 'var(--p-border-radius-sm)',
      },
    },
  },
};
