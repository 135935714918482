export const autocomplete = {
  autocomplete: {
    colorScheme: {
      light: {
        dropdown: {
          background: 'var(--p-primary-500)',
          borderColor: 'var(--p-primary-500)',
          hoverBorderColor: '#0d89ec',
          hoverBackground: '#0d89ec',
          color: 'var(--p-surface-0)',
          hoverColor: 'var(--p-surface-0)',
          activeBorderColor: '#0d89ec',
          activeBackground: '#0d89ec',
          activeColor: 'var(--p-surface-0)',
        },
        chip: {
          borderRadius: 'var(--p-border-radius-xl)',
        },
      },
    },
  },
};
