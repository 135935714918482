export const overlay = {
  overlay: {
    select: {
      borderRadius: 'var(--p-border-radius-sm)',
    },
    popover: {
      shadow: '0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f',
      padding: '0.5rem',
      borderRadius: 'var(--p-border-radius-sm)',
    },
    modal: {
      borderRadius: 'var(--p-border-radius-sm)',
    },
  },
};
