/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Calendar } from '../models/calendar';
import { CalendarListResponse } from '../models/calendar-list-response';
import { createCalendar } from '../fn/calendars/create-calendar';
import { CreateCalendar$Params } from '../fn/calendars/create-calendar';
import { deleteCalendar } from '../fn/calendars/delete-calendar';
import { DeleteCalendar$Params } from '../fn/calendars/delete-calendar';
import { getCalendarById } from '../fn/calendars/get-calendar-by-id';
import { GetCalendarById$Params } from '../fn/calendars/get-calendar-by-id';
import { getCalendars } from '../fn/calendars/get-calendars';
import { GetCalendars$Params } from '../fn/calendars/get-calendars';
import { updateCalendar } from '../fn/calendars/update-calendar';
import { UpdateCalendar$Params } from '../fn/calendars/update-calendar';

@Injectable({ providedIn: 'root' })
export class CalendarsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getCalendars()` */
  static readonly GetCalendarsPath = '/api/v1/calendars';

  /**
   * Get all calendars for tenant.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalendars()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendars$Response(
    params?: GetCalendars$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<CalendarListResponse>> {
    return getCalendars(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all calendars for tenant.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalendars$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendars(
    params?: GetCalendars$Params,
    context?: HttpContext
  ): Observable<CalendarListResponse> {
    return this.getCalendars$Response(params, context).pipe(
      map((r: StrictHttpResponse<CalendarListResponse>): CalendarListResponse => r.body)
    );
  }

  /** Path part for operation `createCalendar()` */
  static readonly CreateCalendarPath = '/api/v1/calendars';

  /**
   * Create new calendar.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCalendar()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalendar$Response(
    params: CreateCalendar$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Calendar>> {
    return createCalendar(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new calendar.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCalendar$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCalendar(params: CreateCalendar$Params, context?: HttpContext): Observable<Calendar> {
    return this.createCalendar$Response(params, context).pipe(
      map((r: StrictHttpResponse<Calendar>): Calendar => r.body)
    );
  }

  /** Path part for operation `getCalendarById()` */
  static readonly GetCalendarByIdPath = '/api/v1/calendars/{id}';

  /**
   * Get calendar by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getCalendarById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarById$Response(
    params: GetCalendarById$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Calendar>> {
    return getCalendarById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get calendar by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getCalendarById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getCalendarById(params: GetCalendarById$Params, context?: HttpContext): Observable<Calendar> {
    return this.getCalendarById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Calendar>): Calendar => r.body)
    );
  }

  /** Path part for operation `updateCalendar()` */
  static readonly UpdateCalendarPath = '/api/v1/calendars/{id}';

  /**
   * Update calendar.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCalendar()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalendar$Response(
    params: UpdateCalendar$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<Calendar>> {
    return updateCalendar(this.http, this.rootUrl, params, context);
  }

  /**
   * Update calendar.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCalendar$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateCalendar(params: UpdateCalendar$Params, context?: HttpContext): Observable<Calendar> {
    return this.updateCalendar$Response(params, context).pipe(
      map((r: StrictHttpResponse<Calendar>): Calendar => r.body)
    );
  }

  /** Path part for operation `deleteCalendar()` */
  static readonly DeleteCalendarPath = '/api/v1/calendars/{id}';

  /**
   * Delete calendar.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteCalendar()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalendar$Response(
    params: DeleteCalendar$Params,
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    return deleteCalendar(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete calendar.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteCalendar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteCalendar(params: DeleteCalendar$Params, context?: HttpContext): Observable<void> {
    return this.deleteCalendar$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }
}
