/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { Calendar } from '../../models/calendar';

export interface GetCalendarById$Params {
  /**
   * Id of a resource.
   */
  id: string;

  /**
   * If set to true every calendar includes its events.
   */
  includeEvents?: boolean;

  /**
   * Start date for included events. Setting this value activates includeEvents=true automatically.
   */
  events_from?: string;

  /**
   * End date for included events. Setting this value activates includeEvents=true automatically.
   */
  events_to?: string;
}

export function getCalendarById(
  http: HttpClient,
  rootUrl: string,
  params: GetCalendarById$Params,
  context?: HttpContext
): Observable<StrictHttpResponse<Calendar>> {
  const rb = new RequestBuilder(rootUrl, getCalendarById.PATH, 'get');
  if (params) {
    rb.path('id', params.id, { style: 'simple', explode: false });
    rb.query('includeEvents', params.includeEvents, { style: 'form', explode: true });
    rb.query('events_from', params.events_from, { style: 'form', explode: true });
    rb.query('events_to', params.events_to, { style: 'form', explode: true });
  }

  return http.request(rb.build({ responseType: 'json', accept: 'application/json', context })).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Calendar>;
    })
  );
}

getCalendarById.PATH = '/api/v1/calendars/{id}';
