/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { QueuedEventList } from '../../models/queued-event-list';

export interface GetAllQueuedEvents$Params {

/**
 * Find events with a given status
 */
  status?: 'CREATED' | 'FAILED_UPDATE_CASE_IN_TF' | 'SKIPPED' | 'FAILED';

/**
 * Find events with a given type
 */
  type?: 'case_created' | 'case_updated' | 'case_finished' | 'case_status_updated' | 'location_updated' | 'driver_duty_status_update' | 'case_callback';
}

export function getAllQueuedEvents(http: HttpClient, rootUrl: string, params?: GetAllQueuedEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<QueuedEventList>> {
  const rb = new RequestBuilder(rootUrl, getAllQueuedEvents.PATH, 'get');
  if (params) {
    rb.query('status', params.status, {"style":"form","explode":true});
    rb.query('type', params.type, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<QueuedEventList>;
    })
  );
}

getAllQueuedEvents.PATH = '/api/v1/queued_events';
