/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteQueuedEvent } from '../fn/queued-events/delete-queued-event';
import { DeleteQueuedEvent$Params } from '../fn/queued-events/delete-queued-event';
import { getAllQueuedEvents } from '../fn/queued-events/get-all-queued-events';
import { GetAllQueuedEvents$Params } from '../fn/queued-events/get-all-queued-events';
import { QueuedEvent } from '../models/queued-event';
import { QueuedEventList } from '../models/queued-event-list';
import { updateQueuedEvent } from '../fn/queued-events/update-queued-event';
import { UpdateQueuedEvent$Params } from '../fn/queued-events/update-queued-event';

@Injectable({ providedIn: 'root' })
export class QueuedEventsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getAllQueuedEvents()` */
  static readonly GetAllQueuedEventsPath = '/api/v1/queued_events';

  /**
   * Get all queued events.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllQueuedEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllQueuedEvents$Response(params?: GetAllQueuedEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<QueuedEventList>> {
    return getAllQueuedEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all queued events.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllQueuedEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllQueuedEvents(params?: GetAllQueuedEvents$Params, context?: HttpContext): Observable<QueuedEventList> {
    return this.getAllQueuedEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueuedEventList>): QueuedEventList => r.body)
    );
  }

  /** Path part for operation `deleteQueuedEvent()` */
  static readonly DeleteQueuedEventPath = '/api/v1/queued_events/{id}';

  /**
   * Delete event by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteQueuedEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQueuedEvent$Response(params: DeleteQueuedEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteQueuedEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete event by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteQueuedEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteQueuedEvent(params: DeleteQueuedEvent$Params, context?: HttpContext): Observable<void> {
    return this.deleteQueuedEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `updateQueuedEvent()` */
  static readonly UpdateQueuedEventPath = '/api/v1/queued_events/{id}';

  /**
   * Update event by id.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateQueuedEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateQueuedEvent$Response(params: UpdateQueuedEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<QueuedEvent>> {
    return updateQueuedEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Update event by id.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateQueuedEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateQueuedEvent(params: UpdateQueuedEvent$Params, context?: HttpContext): Observable<QueuedEvent> {
    return this.updateQueuedEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<QueuedEvent>): QueuedEvent => r.body)
    );
  }

}
