export const tabs = {
  tabs: {
    colorScheme: {
      light: {
        tablist: {
          borderColor: '#dee2e6',
        },
      },
    },
    tab: {
      fontWeight: '400',
      padding: '.9rem 2rem',
      borderWidth: '0 0 2px 0',
      borderColor: '#dee2e6',
      margin: '0 0 -2px 0',
      hoverColor: 'var(--p-text-color-secondary)',
      hoverBorderColor: 'var(--p-text-color-secondary)',
    },
    tablist: {
      borderWidth: '0 0 2px 0',
    },
  },
};
