import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';
import { border } from './primitive/border';
import {
  primary,
  primaryLightColorScheme,
  surface,
  gray,
  highlight,
  focus,
  formField,
  text,
  anchor,
  overlay,
  list,
  mask,
} from './semantic';
import {
  badge,
  button,
  datatable,
  datepicker,
  multiselect,
  popover,
  tabs,
  toggleswitch,
  card,
  autocomplete,
  accordion,
  tree,
  treeselect,
  panel,
  tag,
} from './components';

const tourfoldTheme = definePreset(Aura, {
  primitive: {
    ...border,
  },
  semantic: {
    ...primary,
    colorScheme: {
      light: {
        ...primaryLightColorScheme,
        ...surface,
        ...gray,
        ...highlight,
        ...focus,
        ...formField,
        ...text,
        ...anchor,
        ...overlay,
        ...list,
        ...mask,
      },
    },
  },
  components: {
    ...toggleswitch,
    ...button,
    ...multiselect,
    ...tabs,
    ...datatable,
    ...popover,
    ...datepicker,
    ...badge,
    ...card,
    ...autocomplete,
    ...accordion,
    ...tree,
    ...treeselect,
    ...panel,
    ...tag,
  },
});

export default tourfoldTheme;
