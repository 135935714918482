export const datatable = {
  datatable: {
    colorScheme: {
      light: {
        header: {
          background: '#f8f9fa',
          cell: {
            selectedColor: 'var(--p-primary-color)',
            selectedBackground: '#f8f9fa',
            background: '#f8f9fa',
            color: 'var(--p-text-color)',
            hoverColor: 'var(--p-text-color)',
            hoverBackground: '#e9ecef',
          },
        },
        row: {
          hoverColor: 'var(--p-text-color)',
          hoverBackground: '#e9ecef',
        },
      },
    },
  },
};
