import { ApiConfigurationParams } from '@dispo-shared/open-api/api-configuration';

export class ApiUrlProvider implements ApiConfigurationParams {
  public useApiReviewUrl: boolean | undefined;

  public get rootUrl(): string {
    if (this.useApiReviewUrl) {
      return 'https://api.app-review.int.tourfold.com';
    } else {
      try {
        const apiBaseUrl = (window as any).spaConfig.endpoints.api;
        return apiBaseUrl;
      } catch (error) {
        console.error('SpaConfig error', error);
        return 'https://dispo-xyz-api.prod.dispo.xyz';
      }
    }
  }
}

export abstract class SpaConfig {
  public static apiUrlProvider: ApiUrlProvider = new ApiUrlProvider();
}
